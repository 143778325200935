<template>
  <div>
    <div class="hards-list">
      <!-- v-show="!edit.mode" -->
      <!-- List group -->
      <!-- https://getbootstrap.com/docs/4.0/components/list-group/ -->
      <ul class="list-group">
        <li class="list-group-item" v-for="hard in hards.Result" :key="hard">
          <Hard :id="hard" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Hard from "./Hard.vue";

const cmdHards = "hards-list";
const cmdHardsChanged = "hards-list-changed";
const cmdHardsSubscribe = "hards-list-subscribe";
const cmdHardsUnsubscribe = "hards-list-unsubscribe";

export default {
  name: "HardList",
  components: {
    Hard,
  },
  data() {
    return {
      hards: {
        Result: [],
      },
    };
  },

  mounted: function () {
    const that = this;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }
      switch (gw.command) {
        case cmdHards:
          that.hards = JSON.parse(data);
          if (that.hards.Result == null) {
            that.hards.Result = [];
          }
          break;

        case cmdHardsChanged:
          that.updatePlayers(JSON.parse(data));
          break;
      }
    });

    // Send cmdHards commands to WebRTC server when connected
    this.teoweb.whenConnected(() => {
      this.teoweb.sendCmd(cmdHards);
      this.teoweb.sendCmd(cmdHardsSubscribe);
    });
  },

  unmounted: function () {
    this.teoweb.sendCmd(cmdHardsUnsubscribe);
    this.teoweb.delReader(this.reader);
  },

  methods: {
    updatePlayers(playersChange) {
      const element = { game: playersChange.game, id: playersChange.id };
      const hards = this.hards.Result;

      // Add element to hards
      if (playersChange.direction == "+") {
        hards.push(element);
        return;
      }

      // Remove element from hards
      let i = 0;
      while (i < hards.length) {
        if (hards[i] == element.id) {
          hards.splice(i, 1);
          break;
        }
        i++;
      }
    },
  },
};
</script>

<style>
.hards-list {
  min-width: 350px;
  max-width: 635px;
}
.hards-list li.list-group-item {
  border: none;
  padding: inherit;
}
.hards-list li.list-group-item h3 {
  margin-top: 20px;
}
</style>
