<template>
  <div>
    <div v-show="!edit.mode" class="configs-list">
      <!-- List group -->
      <!-- https://getbootstrap.com/docs/4.0/components/list-group/ -->
      <ul class="list-group">
        <li
          class="list-group-item"
          v-for="config in configs.Result"
          :key="config.id"
        >
          <Config :name="config" @edit="doEdit" />
        </li>
      </ul>
    </div>
    <EditJson
      v-if="edit.mode"
      :edit="edit"
      @save="doSave"
      @cancel="edit.mode = false"
    />
  </div>
</template>

<script>
import Config from "./Config.vue";
import EditJson from "./EditJson.vue";

const cmdConfigs = "configs";
const cmdConfigSave = "config-save";
const cmdAccessWrite = "access-write";

export default {
  name: "ConfigsList",
  components: {
    Config,
    EditJson,
  },
  data() {
    return {
      configs: {},
      edit: {
        mode: false,
        json: null,
        name: "",
        canSave: false,
        cmdSave: cmdConfigSave,
        copy: "",
      },
    };
  },

  mounted: function () {
    let that = this;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }
      switch (gw.command) {
        case cmdAccessWrite:
          that.edit.canSave = true;
          break;
        case cmdConfigs:
          that.configs = JSON.parse(data);
          break;
      }
    });

    // Send cmdAccessWrite amd cmdConfigs commands to WebRTC server when connected
    this.teoweb.whenConnected(() => {
      this.teoweb.sendCmd(cmdAccessWrite);
      this.teoweb.sendCmd(cmdConfigs);
    });
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    /** set edit parameters and start json edit */
    doEdit(name, json, copy) {
      console.debug("ConfigsList need to edit:", name);
      this.edit.mode = true;
      this.edit.name = name;
      this.edit.json = json;
      this.edit.copy = copy;
    },

    /** save json object after edit */
    doSave() {
      this.edit.mode = false;

      // Reset configs and send 'configs' command
      this.configs = {};
      this.teoweb.sendCmd(cmdConfigs);
    },
  },
};
</script>

<style>
.configs-list {
  min-width: 350px;
  max-width: 635px;
}
</style>
