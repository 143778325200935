<template>
  <div>
    <div>
      <h1>Maps</h1>
      <div class="counts">
        <!-- Maps total -->
        <span class="badge rounded-pill bg-secondary">
          {{ count.maps }}
          <span class="visually-hidden">maps total</span>
        </span>

        <!-- Maps online -->
        <span class="badge rounded-pill bg-success">
          {{ count.online }}
          <span class="visually-hidden">maps online</span>
        </span>

        <!-- Players total -->
        <span class="badge rounded-pill bg-warning">
          {{ count.players }}
          <span class="visually-hidden">players total</span>
        </span>
      </div>
    </div>
    <MapsList
      @countMaps="(n) => (count.maps = n)"
      @countOnline="(o, p) => ((count.online = o), (count.players = p))"
    />
  </div>
</template>

<script>
import MapsList from "../components/MapsList.vue";

export default {
  name: "Maps",
  components: {
    MapsList,
  },
  data() {
    return {
      count: { maps: 0, online: 0, players: 0 },
      countOnline: 0,
    };
  },
};
</script>

<style scoped>
.counts {
  position: relative;
  left: 89px;
  top: -50px;
  margin-bottom: -22px;
}
</style>
