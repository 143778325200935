<template>
  <div v-if="player" class="player">
    <h3>{{ capitalise(player.name) }}</h3>
    <div>
      Id: {{ id }}<br />
      MapId: {{ player.map_id }}<br />
      Game: {{ player.game }}<br />
      Level: {{ player.level }}<br />
      Region: {{ player.region }}<br />
    </div>
  </div>
</template>

<script>
const cmdPlayer = "player/";

export default {
  name: "Player",
  props: {
    game: String,
    id: String,
  },
  data() {
    return {
      player: null,
      confirm: false,
    };
  },

  mounted: function () {
    let that = this;
    let cmd = cmdPlayer + this.game + "/" + this.id;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }
      switch (gw.command) {
        case cmd:
          that.player = JSON.parse(data);
          break;
      }
    });

    // Send 'player/{game}/{id}' command to WebRTC server when connected
    this.teoweb.whenConnected(() => this.teoweb.sendCmd(cmd));
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    capitalise(name) {
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
  },
};
</script>

<style scoped>
.btn-sm {
  margin-right: 2px;
}
</style>
