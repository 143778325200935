<template>
  <div v-if="config" class="config">
    <h4>{{ name }}</h4>
    <div>
      FileId: {{ config.FileId }}<br />
      LocalTestIP: {{ config.LocalTestIP }}<br />
      LocalTestPort: {{ config.LocalTestPort }}<br />

      <button
        type="button"
        @click="$emit('edit', name, config)"
        class="btn btn-outline-primary btn-sm mt-2"
      >
        Edit
      </button>

      <button
        type="button"
        @click="$emit('edit', name, config, 'copy')"
        class="btn btn-outline-warning btn-sm mt-2"
      >
        Copy
      </button>

      <button
        type="button"
        class="btn btn-outline-danger btn-sm mt-2"
        @click="confirm = true"
      >
        Remove
      </button>
      <!-- Remove confirmation -->
      <div v-if="confirm">
        <EditJson
          :edit="{
            confirm: 'Remove',
            cmdConfirm: 'config-remove',
            name: name,
          }"
          @save="config = null"
          @cancel="confirm = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EditJson from "./EditJson.vue";

const cmdConfig = "config/";

export default {
  components: { EditJson },
  name: "Config",
  props: {
    name: String,
  },
  emmits: ["edit"],
  data() {
    return {
      config: null,
      confirm: false,
    };
  },

  mounted: function () {
    let that = this;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }
      switch (gw.command) {
        case cmdConfig + that.name:
          that.config = JSON.parse(data);
          break;
      }
    });

    // Send  'config/{name}' command to WebRTC server when connected
    this.teoweb.whenConnected(() => this.teoweb.sendCmd(cmdConfig + this.name));
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
  },
};
</script>

<style scoped>
.btn-sm {
  margin-right: 2px;
}
</style>
