<template>
  <div>
    <div v-if="!signedIn" class="login-page">
      <transition name="fade">
        <div v-if="authFormType == SIGNIN_FORM" class="wallpaper-login"></div>
      </transition>
      <div class="wallpaper-register"></div>

      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-8 mx-auto">
            <!-- Sign In -->
            <div
              v-if="authFormType == SIGNIN_FORM"
              class="card login"
              v-bind:class="{ error: emptyFields }"
            >
              <h1>Sign In</h1>
              <form class="form-group" action="javascript:void(0);">
                <input
                  v-model="emailLogin"
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  required
                />
                <input
                  v-model="passwordLogin"
                  type="password"
                  class="form-control"
                  placeholder="Password"
                  autocomplete="on"
                  required
                />
                <input
                  type="submit"
                  class="btn btn-primary"
                  @click="doLogin"
                  value="SignIn"
                />
                <input
                  type="button"
                  class="btn btn-primary"
                  @click="doLoginGoogle"
                  value="Google"
                />
                <p>
                  Don't have an account?
                  <a
                    href="javascript:void(0);"
                    @click="
                      (authFormType = SIGNUP_FORM),
                        ((emptyFields = false), (errMsg = ''))
                    "
                    >Sign up here</a
                  >
                </p>
                <p>
                  <a
                    href="javascript:void(0);"
                    @click="
                      (authFormType = RESET_PASSWORD_FORM),
                        ((emptyFields = false), (errMsg = ''))
                    "
                    >Forgot your password?</a
                  >
                </p>
              </form>
            </div>

            <!-- Sign Up -->
            <div
              v-if="authFormType == SIGNUP_FORM"
              class="card register"
              v-bind:class="{ error: emptyFields }"
            >
              <h1>Sign Up</h1>
              <form class="form-group" action="javascript:void(0);">
                <input
                  v-model="emailReg"
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  required
                />
                <input
                  v-model="passwordReg"
                  type="password"
                  class="form-control"
                  placeholder="Password"
                  autocomplete="on"
                  required
                />
                <input
                  v-model="confirmReg"
                  type="password"
                  class="form-control"
                  placeholder="Confirm Password"
                  autocomplete="on"
                  required
                />
                <input
                  type="submit"
                  class="btn btn-primary"
                  @click="doRegister"
                />
                <p>
                  Already have an account?
                  <a
                    href="javascript:void(0);"
                    @click="
                      (authFormType = SIGNIN_FORM),
                        ((emptyFields = false), (errMsg = ''))
                    "
                    >Sign in here</a
                  >
                </p>
              </form>
            </div>

            <!-- Email Verify -->
            <div
              v-if="authFormType == VERIFY_FORM"
              class="card verify"
              v-bind:class="{ error: emptyFields }"
            >
              <h1>Verifying email address</h1>
              <form class="form-group" action="javascript:void(0);">
                <p>
                  Please check your email and press verify link there.<br />
                  <br />
                  And then
                  <a
                    href="javascript:void(0);"
                    @click="
                      (authFormType = SIGNIN_FORM),
                        ((emptyFields = false), (errMsg = ''))
                    "
                    >Sign in here</a
                  >
                </p>
              </form>
            </div>

            <!-- Password reset email -->
            <div
              v-if="authFormType == RESET_PASSWORD_FORM"
              class="card login"
              v-bind:class="{ error: emptyFields }"
            >
              <h1>Reset Password</h1>
              <form class="form-group" action="javascript:void(0);">
                <input
                  v-model="emailReset"
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  required
                />
                <input
                  type="submit"
                  class="btn btn-primary"
                  @click="doResetPassword"
                />
                <p>
                  To reset your password enter Email and press Submit button.
                  Check your email and follow its instructions.<br />
                  <br />
                  And then
                  <a
                    href="javascript:void(0);"
                    @click="
                      (authFormType = SIGNIN_FORM),
                        ((emptyFields = false), (errMsg = ''))
                    "
                    >Sign in here</a
                  >
                </p>
              </form>
            </div>

            <div class="card errmsg" v-if="errMsg">{{ errMsg }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="signedIn && user" class="login-signout">
      <button
        class="btn btn-outline-secondary btn-sm"
        type="button"
        @click="doLogout()"
      >
        <img class="img-thumbnail" :src="user.photoURL" />
        Sign Out
      </button>
    </div>
  </div>
</template>

<script>
const cmdCheckToken = "checkToken";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  GoogleAuthProvider,
  signInWithPopup,
  getAuth,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { initializeApp } from "firebase/app";

export default {
  name: "SignIn",
  created() {
    this.SIGNIN_FORM = 0;
    this.SIGNUP_FORM = 1;
    this.VERIFY_FORM = 2;
    this.RESET_PASSWORD_FORM = 3;
  },
  // props: {
  //   auth: Object,
  // },
  data() {
    return {
      authFormType: 0,
      emailLogin: "",
      passwordLogin: "",
      emailReg: "",
      passwordReg: "",
      confirmReg: "",
      emailReset: "",
      emptyFields: false,
      errMsg: "",

      auth: null,
      signedIn: true,
      user: null,
    };
  },
  mounted: function () {
    console.log("signIn started");
    this.authInit();
  },
  methods: {
    authInit() {
      // Init Firebase Auth
      const firebaseConfig = {
        apiKey: "AIzaSyABOvyym5VChUeHam8h0xGvTvzRDcRm0xk",
        authDomain: "teo-test.firebaseapp.com",
      };
      const fbapp = initializeApp(firebaseConfig);
      this.auth = getAuth(fbapp);
      let that = this;

      onAuthStateChanged(this.auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid = user.uid;
          console.log("user signed in", uid);

          user.getIdToken(true).then((idToken) => {
            console.log("get token len:", idToken.length);

            if (!user.emailVerified) {
              console.log("email has not verified");
              this.doLogout();
              return;
            }

            this.teoweb.whenConnected(function () {
              that.teoweb.token = idToken;
              that.teoweb.sendCmd(cmdCheckToken, idToken);
            });

            this.user = user;
          });
        } else {
          // User is signed out
          // ...
          this.signedIn = false;
          this.userMsg = "signed out";
          console.log("user is signed out");
          this.$emit("ready", false);
        }
      });

      this.teoweb.addReader(function (gw, data) {
        // console.debug(
        //   "execute reader in 'AppTitle.vue' with parameters, gw:",
        //   gw,
        //   "data:",
        //   data
        // );
        switch (gw.command) {
          case cmdCheckToken:
            console.debug("got " + cmdCheckToken + " answer: " + data);

            if (gw.err) {
              that.errMsg = gw.err;
              that.doLogout();
              break;
            }

            if (data == "ok") {
              that.signedIn = true;
              that.userMsg = "signed in";
              that.$emit("ready", true);
            }
            break;
        }
      });
    },

    doLogin() {
      console.log("start signIn");
      this.errMsg = "";
      signInWithEmailAndPassword(this.auth, this.emailLogin, this.passwordLogin)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log("signin:", user);
          if (!user.emailVerified) {
            this.authFormType = this.VERIFY_FORM;
          }
        })
        .catch((error) => {
          console.log("can't signin:", error.code, error.message);
          this.errMsg = error.message;
        });
    },

    doLogout() {
      this.teoweb.token = null;
      this.signedIn = false;
      signOut(this.auth)
        .then(() => {
          // Sign-out successful.
          console.log("signOut successful");
        })
        .catch((error) => {
          // An error happened.
          console.log("signOut error:", error);
        });
    },

    doLoginGoogle() {
      this.errMsg = "";
      const provider = new GoogleAuthProvider();
      signInWithPopup(this.auth, provider)
        .then((userCredential) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          // const credential = GoogleAuthProvider.credentialFromResult(userCredential);
          // const token = credential.accessToken;
          // The signed-in user info.
          // const user = userCredential.user;
          // ...
          const user = userCredential.user;
          console.log("signin:", user);
        })
        .catch((error) => {
          // Handle Errors here.
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // The email of the user's account used.
          // const email = error.customData.email;
          // The AuthCredential type that was used.
          // const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
          console.log("can't signin:", error.code, error.message);
          this.errMsg = error.message;
        });
    },

    doRegister() {
      console.log("start register");
      this.errMsg = "";
      if (
        this.emailReg === "" ||
        this.passwordReg === "" ||
        this.confirmReg === "" ||
        this.passwordReg !== this.confirmReg
      ) {
        this.emptyFields = true;
        if (this.passwordReg !== this.confirmReg) {
          this.errMsg = "Error Confirm password not equal to Password";
        }
        return;
      }

      createUserWithEmailAndPassword(this.auth, this.emailReg, this.passwordReg)
        .then((userCredential) => {
          console.log("registering success", userCredential.user);
          sendEmailVerification(userCredential.user)
            .then(() => {
              // Email verification sent!
              console.log("Email Verification sent! Check your mail box");
              this.authFormType = this.VERIFY_FORM;
            })
            .catch((error) => {
              console.log(
                "can't send email verification:",
                error.code,
                error.message
              );
              this.errMsg = error.message;
              this.authFormType = this.VERIFY_FORM;
            });
        })
        .catch((error) => {
          console.log("can't registering:", error.code, error.message);
          this.errMsg = error.message;
        });
    },

    doResetPassword() {
      this.errMsg = "";
      sendPasswordResetEmail(this.auth, this.emailReset)
        .then(() => {
          // Password reset email sent!
          // ..
          console.log("password reset email sent");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(
            "can't send reset password email, error:",
            errorCode,
            errorMessage
          );
          this.errMsg = error.message;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
  line-height: 1rem;
}

.btn {
  margin-right: 2px;
}

.login-signout .btn {
  padding: 0;
  padding-right: 11px;
}
.login-signout img {
  height: 37px;
  /* margin-right: 4px; */
}

.card {
  padding: 20px;
}

.form-group input {
  margin-bottom: 20px;
}

.login-page {
  align-items: center;
  display: flex;
  /* height: 100vh; */

  .wallpaper-login {
    background: url(https://images.pexels.com/photos/32237/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
      no-repeat center center;
    background-size: cover;
    height: 100%;
    position: absolute;
    width: 100%;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .wallpaper-register {
    background: url(https://images.pexels.com/photos/533671/pexels-photo-533671.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
      no-repeat center center;
    background-size: cover;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  h1 {
    margin-bottom: 1.5rem;
  }
}

.error {
  animation-name: errorShake;
  animation-duration: 0.3s;
}

.card.errmsg {
  color: red;
}

@keyframes errorShake {
  0% {
    transform: translateX(-25px);
  }
  25% {
    transform: translateX(25px);
  }
  50% {
    transform: translateX(-25px);
  }
  75% {
    transform: translateX(25px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
