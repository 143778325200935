<template>
  <div>
    <button class="btn btn-outline-secondary" type="button" @click="doApply">
      Restart configuration
    </button>
    <div class="subject">
      Press this button to applay changes in config files.
    </div>
    <div v-if="answer" class="answer">{{ answer }}</div>
    <div v-if="error" class="error">{{ error }}</div>
  </div>
</template>

<script>
const cmdApplyConfigs = "apply-configs";

export default {
  name: "ApplyConfigs",
  data() {
    return {
      error: "",
      answer: "",
    };
  },

  mounted: function () {
    let that = this;
    this.reader = this.teoweb.addReader(function (gw, data) {
      // console.debug(
      //   "execute reader in 'UpdateAMI.vue' with parameters, gw:",
      //   gw,
      //   "data:",
      //   data
      // );
      switch (gw.command) {
        case cmdApplyConfigs:
          if (gw.err) {
            that.error = "Error: " + gw.err;
            break;
          }
          that.answer = data;
          break;
      }
    });

    // Send cmdAccessWrite amd cmdMaps commands to WebRTC server when connected
    // this.teoweb.whenConnected(() => this.teoweb.sendCmd(cmdAccessWrite));
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    /** send apply ami request */
    doApply() {
      console.debug("doApply");
      this.answer = "";
      this.error = "";
      this.teoweb.sendCmd(cmdApplyConfigs);
    },
  },
};
</script>

<style scoped>
.subject {
  color: gray;
  font-size: small;
  margin-bottom: 15px;
}
.answer {
  color: green;
}
.error {
  color: red;
}
</style>
