<!-- Title and Online info -->
<template>
  <div class="app-title row">
    <div class="logo col col-lg-1 col-sm-2 col-2">
      <!-- Logo -->
      <a href="./">
        <img alt="Vue logo" src="../assets/logo.png" />
      </a>
    </div>
    <div class="col">
      <!-- Title -->
      <h2>{{ name }}</h2>

      <!-- Some info from server -->
      <div class="subtitle">
        <div class="uptime">server uptime: {{ uptime }}</div>
        <div>
          clients: {{ clients }}, players: {{ players }}, queue: {{ queue }}
        </div>
        <div>{{ version }}</div>
        <div v-if="online" class="online">online</div>
        <div v-if="!online" class="offline">offline</div>
        <button v-if="disconnected" type="button" @click="reconnect()">
          Reconnect
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";

const prefix = "/api/v1/";
// const cmdSubscribe = "subscribe";
const cmdClients = "clients";
const cmdPlayers = "players";
const cmdPlayersSubscribe = "players-subscribe";
const cmdPlayersUnsubscribe = "players-unsubscribe";
const cmdPlayersQueue = "players-queue";
const cmdPlayersQueueSubscribe = "players-queue-subscribe";
const cmdPlayersQueueUnsubscribe = "players-queue-unsubscribe";
const cmdCheckToken = "checkToken";

export default {
  name: "AppTitle",

  data() {
    return {
      name: "Loading ...",
      uptime: "0",
      version: "ver. 0.0.2",
      clients: "0",
      players: "0",
      queue: "0",
      online: false,
      disconnected: false,
    };
  },
  mounted: function () {
    let that = this;
    // Get this browser name from local storage
    this.teoname = uuid.v1();
    console.debug("teoname:", this.teoname);

    // Get name and version from local storage
    if (localStorage.name) this.name = localStorage.name;
    if (localStorage.version) this.version = localStorage.version;

    // Get some HTTP data
    this.getName();
    this.getUptime();
    this.getVersion();

    // Connect to Teonet proxy WebRTC server
    const serverName = "server-1"; // TODO: Get WenRTC server name from some parameters
    const port = "8083"; // TODO: Get ws server port from some parameters
    let host;
    let scheme;
    const hostname = window.location.hostname;
    if (hostname == "localhost" || hostname == "127.0.0.1") {
      scheme = "ws";
      host = hostname + ":" + port;
    } else {
      scheme = "wss";
      host = "signal." + hostname;
    }
    this.teoweb.connect(
      scheme + "://" + host + "/signal",
      this.teoname,
      serverName
    );
    this.teoweb.onOpen(function () {
      console.debug("onOpen");
      that.online = true;
      that.disconnected = false;
      that.teoweb.sendCmd(cmdClients);
      that.teoweb.subscribeCmd(cmdClients);
      that.teoweb.sendCmd(cmdPlayers);
      that.teoweb.sendCmd(cmdPlayersSubscribe);
      that.teoweb.sendCmd(cmdPlayersQueue);
      that.teoweb.sendCmd(cmdPlayersQueueSubscribe);
      if (that.teoweb.token) {
        that.teoweb.sendCmd(cmdCheckToken, that.teoweb.token);
      }
    });
    this.teoweb.onClose(function (b) {
      console.debug("onClose");
      that.online = false;
      if (b === true) {
        that.disconnected = true;
      }
    });
    this.teoweb.addReader(function (gw, data) {
      // console.debug(
      //   "execute reader in 'AppTitle.vue' with parameters, gw:",
      //   gw,
      //   "data:",
      //   data
      // );
      switch (gw.command) {
        case cmdClients:
          that.clients = data;
          break;
        case cmdPlayers:
          that.players = data;
          break;
        case cmdPlayersQueue:
          that.queue = data;
          break;
      }
    });
  },
  unmounted: function () {
    this.teoweb.sendCmd(cmdPlayersUnsubscribe);
    this.teoweb.sendCmd(cmdPlayersQueueUnsubscribe);
    this.teoweb.delReader(this.reader);
  },
  methods: {
    getName() {
      this.axios.get(prefix + "name").then((response) => {
        this.name = response.data;
        localStorage.name = this.name;
      });
    },
    getUptime() {
      this.axios
        .get(prefix + "uptime")
        .then((response) => (this.uptime = response.data));
    },
    getVersion() {
      this.axios.get(prefix + "version").then((response) => {
        this.version = "ver. " + response.data;
        localStorage.version = this.version;
      });
    },
    /** Reconnect */
    reconnect() {
      // window.location.href = "./";
      location.reload();
    },
  },
};
</script>

<style>
.app-title {
  margin-top: 2px;
  margin-bottom: 5px;
}

.logo {
  min-width: 112px;
}

.logo img {
  width: 111px;
  margin-top: 1px;
  margin-left: -5px;
}

h2 {
  word-wrap: break-word;
}

.subtitle {
  margin-top: -10px;
  font-size: small;
}

.online {
  color: green;
}

.offline {
  color: red;
}
</style>
