<template>
  <div class="container">
    <AppTitle />
    <!-- <SignIn v-if="!signedIn" :auth="auth" /> -->
    <SignIn @ready="(s) => (signedIn = s)" />

    <div v-if="signedIn">
      <div class="menu">
        <a href="#/">Home</a> | <a href="#/maps">Maps</a> |
        <a href="#/configs">Configs</a> | <a href="#/players">Players</a> |
        <a href="#/groups">Groups</a> | <a href="#/hard">Hards</a> |
        <a href="#/settings">Settings</a>

        <!-- TODO: May be use the Navbar instead of text menu -->
        <!-- <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid">
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div class="navbar-nav">
              <a class="nav-link" href="#/">Home</a>
              <a class="nav-link" href="#/maps">Maps</a>
              <a class="nav-link" href="#/configs">Configs</a>
              <a class="nav-link" href="#/non-existent-path">Broken Link</a>
              <a class="nav-link disabled">Setings</a>
            </div>
          </div>
        </div>
      </nav> -->
      </div>
      <component :is="currentView" />
    </div>
    <hr />
    <div class="copyr">If you can do it better then do it (c) 2023</div>
  </div>
</template>

<script>
import Home from "./pages/Home.vue";
import Hard from "./pages/Hard.vue";
import Maps from "./pages/Maps.vue";
import Configs from "./pages/Configs.vue";
import Players from "./pages/Players.vue";
import Groups from "./pages/Groups.vue";
import Settings from "./pages/Settings.vue";
import NotFound from "./pages/NotFound.vue";

import AppTitle from "./components/AppTitle.vue";
import SignIn from "./components/SignIn.vue";

const routes = {
  "/": Home,
  "/maps": Maps,
  "/configs": Configs,
  "/players": Players,
  "/groups": Groups,
  "/hard": Hard,
  "/settings": Settings,
};

export default {
  name: "App",
  components: {
    AppTitle,
    SignIn,
  },
  data() {
    return {
      signedIn: false,
      currentPath: window.location.hash,
    };
  },
  computed: {
    currentView() {
      return routes[this.currentPath.slice(1) || "/"] || NotFound;
    },
  },
  mounted() {
    window.addEventListener("hashchange", () => {
      this.currentPath = window.location.hash;
    });
  },
  methods: {
    doReady(sign) {
      this.signedIn = sign;
    },
  },
};
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
} */

.menu {
  margin-bottom: 15px;
}

.copyr {
  margin-bottom: 20px;
  font-size: small;
  color: gray;
}
</style>
