<template>
  <div>
    <h1>Configs</h1>
    <ConfigsList />
  </div>
</template>

<script>
import ConfigsList from "../components/ConfigsList.vue";

export default {
  name: "Configs",
  components: {
    ConfigsList,
  },
};
</script>
