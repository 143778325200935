<template>
  <div>
    <div v-show="!edit.mode" class="maps-list">
      <div class="filter">
        <div class="input-group">
          <!-- filter: -->
          <input
            class="form-control"
            v-model="filter"
            placeholder="Enter filter text"
          />
          <button
            type="button"
            class="btn bg-transparent"
            @click="clearFilters()"
            style="margin-left: -40px; margin-bottom: 5px; z-index: 100"
          >
            <i class="fa fa-times"></i>x
          </button>

          <button
            type="button"
            class="btn btn-primary"
            @click="changefilterOnline()"
          >
            {{ filterOnlineBtnName }}
          </button>
        </div>

        <!-- <button type="button" class="btn btn-primary" @click="clearFilters()">
          Clear Filters
        </button> -->
      </div>
      <!-- List group -->
      <!-- https://getbootstrap.com/docs/4.0/components/list-group/ -->
      <ul class="list-group">
        <li class="list-group-item" v-for="map in maps.Result" :key="map.mapId">
          <div>
            <MapDetails
              :regionId="map.regId"
              :mapId="map.mapId"
              :filter="filter"
              :filterOnline="filterOnline"
              @edit="doEdit"
              @countOnline="doCountOnline"
            />
          </div>
        </li>
      </ul>
    </div>
    <EditJson
      v-if="edit.mode"
      :edit="edit"
      @save="doSave"
      @cancel="edit.mode = false"
    />
  </div>
</template>

<script>
import MapDetails from "./MapDetails.vue";
import EditJson from "./EditJson.vue";

const cmdMaps = "maps";
const cmdMapsUpdate = "maps-update";
const cmdMapsSubscribe = "maps-subscribe";
const cmdMapsUnsubscribe = "maps-unsubscribe";
const cmdMapSave = "map-save";
const cmdAccessWrite = "access-write";

export default {
  name: "MapsList",
  components: {
    MapDetails,
    EditJson,
  },
  data() {
    return {
      maps: {},
      edit: {
        mode: false,
        json: null,
        name: "",
        params: [],
        canSave: false,
        cmdSave: cmdMapSave,
        copy: "",
      },
      count: {
        online: 0,
        numUsers: 0,
      },
      filter: "",
      filterOnline: 0,
      filterOnlineBtnName: "All",
    };
  },

  mounted: function () {
    // Read filter settings
    if (localStorage.filter) {
      this.filter = localStorage.filter;
    }
    if (localStorage.filterOnline) {
      this.filterOnline = parseInt(localStorage.filterOnline);
    }
    if (localStorage.filterOnlineBtnName) {
      this.filterOnlineBtnName = localStorage.filterOnlineBtnName;
    }

    // Add 'reader' which will receive data from WebRTC Data Channel
    let that = this;
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }
      switch (gw.command) {
        case cmdAccessWrite:
          that.edit.canSave = true;
          break;
        case cmdMaps:
          that.maps = JSON.parse(data);
          that.$emit("countMaps", that.maps.Result.length);
          break;
        case cmdMapsUpdate:
          that.teoweb.sendCmd(cmdMaps);
      }
    });

    // Send cmdAccessWrite amd cmdMaps commands to WebRTC server when connected
    this.teoweb.whenConnected(() => {
      this.teoweb.sendCmd(cmdAccessWrite);
      this.teoweb.sendCmd(cmdMaps);
      this.teoweb.sendCmd(cmdMapsSubscribe);
    });
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
    this.teoweb.sendCmd(cmdMapsUnsubscribe);

    // Write filter settings
    localStorage.filter = this.filter;
    localStorage.filterOnline = this.filterOnline;
    localStorage.filterOnlineBtnName = this.filterOnlineBtnName;
  },

  methods: {
    /** set edit parameters and start json edit */
    doEdit(name, json, params, copy) {
      console.debug("MapsList need to edit:", name);
      this.edit.mode = true;
      this.edit.name = name;
      this.edit.params = params;
      this.edit.json = json;
      this.edit.copy = copy;
    },

    /** emit countOnline to parent */
    doCountOnline(onlineDiff, numUsersDiff) {
      this.count.online += onlineDiff;
      this.count.numUsers += numUsersDiff;
      this.$emit("countOnline", this.count.online, this.count.numUsers);
    },

    /** save json object after edit */
    doSave() {
      this.edit.mode = false;

      // Reset configs and send 'configs' command
      this.clearMaps();
      this.teoweb.sendCmd(cmdMaps);
    },
    /** Change state filter online value and buttons name */
    changefilterOnline() {
      this.filterOnline = this.filterOnline < 2 ? ++this.filterOnline : 0;

      switch (this.filterOnline) {
        case 0:
          this.filterOnlineBtnName = "All";
          break;
        case 1:
          this.filterOnlineBtnName = "Online";
          break;
        case 2:
          this.filterOnlineBtnName = "Offline";
          break;
      }
    },
    // clear all filters information
    clearFilters() {
      this.filter = "";
      this.filterOnline = 0;
      this.filterOnlineBtnName = "All";
    },
    // Clear maps
    clearMaps() {
      this.maps = {};
    },
  },
};
</script>

<style>
.maps-list {
  min-width: 350px;
  max-width: 635px;
}

.newid {
  margin-right: 0.5rem;
  width: 300px;
}

.filter {
  margin-bottom: 2px;
}

.maps-list li.list-group-item {
  border: none;
  padding: inherit;
}
.maps-list li.list-group-item h3 {
  margin-top: 20px;
}
</style>
