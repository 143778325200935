<template>
  <div v-if="canSave">
    <button
      class="btn btn-outline-secondary dropdown-toggle"
      type="button"
      @click="showForm = !showForm"
    >
      Update AMI
    </button>
    <div class="subject">
      AWS AMI used in Asuzs to create new play-servers. When the new build of
      the game server is ready, we need to update the AMI.
    </div>
    <div v-if="showForm">
      <form
        class="form-group"
        v-on:submit="doUpdate(params)"
        action="javascript:void(0);"
      >
        <label for="params.game">Game</label>
        <input
          class="form-control"
          v-model="params.game"
          placeholder="Enter game name"
          required
        />

        <label for="params.regId">RegionId</label>
        <input
          class="form-control"
          v-model="params.regId"
          placeholder="Enter region id or leave blank for all regions"
        />

        <label for="params.level">Map level name</label>
        <input
          class="form-control"
          v-model="params.level"
          placeholder="Enter level name or leave blank for all levels"
        />

        <div v-if="answer" class="answer">{{ answer }}</div>
        <div v-if="error" class="error">{{ error }}</div>
        <div class="buttons">
          <input
            type="button"
            class="btn btn-secondary"
            @click="showForm = false"
            value="Cancel"
          />
          <button type="submit" class="btn btn-primary float-end">
            Update AMI
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
const cmdUpdateAMI = "updateAMI/";
const cmdAccessWrite = "access-write";

export default {
  name: "UpdateAMI",
  data() {
    return {
      showForm: false,
      canSave: false,
      params: {
        game: "",
        regId: "",
        level: "",
      },

      answer: "",
      error: "",
    };
  },

  mounted: function () {
    let that = this;
    this.reader = this.teoweb.addReader(function (gw, data) {
      // console.debug(
      //   "execute reader in 'UpdateAMI.vue' with parameters, gw:",
      //   gw,
      //   "data:",
      //   data
      // );
      switch (gw.command) {
        case cmdAccessWrite:
          if (!gw.err) {
            that.canSave = true;
          }
          break;

        case that.makeCmd(that.params):
          console.debug("cmdUpdateAMI answer received");
          if (gw.err) {
            that.error = "Error: " + gw.err;
            break;
          }
          that.answer += data + "\n";
          break;
      }
    });

    // Send cmdAccessWrite amd cmdMaps commands to WebRTC server when connected
    this.teoweb.whenConnected(() => this.teoweb.sendCmd(cmdAccessWrite));
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    /** send update ami request */
    doUpdate(params) {
      // console.debug(
      //   "doUpdate",
      //   params.game,
      //   params.regId,
      //   params.level
      // );
      this.error = "";
      this.answer = "";
      this.teoweb.sendCmd(this.makeCmd(params));
    },
    makeCmd(params) {
      return (
        cmdUpdateAMI + params.game + "/" + params.regId + "/" + params.level
      );
    },
  },
};
</script>

<style scoped>
.subject {
  color: gray;
  font-size: small;
  margin-bottom: 15px;
}
input.form-control {
  margin-bottom: 15px;
}
.answer {
  color: green;
  white-space: pre-wrap;
}
.error {
  color: red;
}
.buttons {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
