<template>
  <div class="mapDetails" v-if="checkFilter()">
    <h3>{{ mapDetails ? mapDetails.FriendlyName : "" }}</h3>
    <div v-if="mapDetails">
      ID: {{ mapId }}<br />
      Game: {{ mapDetails.GameName }}<br />
      Level: {{ mapDetails.LevelName }}<br />
      Region: {{ mapDetails.AwsRegion }}<br />
      Config: {{ mapDetails.ConfigFile }}<br />
      IP: {{ mapDetails.ServerIP }}<br />
      Port: {{ mapDetails.ServerPort }}<br />
      <div v-if="online.online" class="server-online">
        online / {{ online.numUsers }}
      </div>
      <div v-else class="server-offline">offline</div>
      <button
        type="button"
        @click="
          $emit('edit', mapId, mapDetails, [
            { name: 'RegionId', value: regionId },
          ])
        "
        class="btn btn-outline-primary btn-sm mt-2"
      >
        Edit
      </button>

      <button
        type="button"
        @click="
          $emit(
            'edit',
            mapId,
            mapDetails,
            [{ name: 'regionId', value: regionId }],
            'copy'
          )
        "
        class="btn btn-outline-warning btn-sm mt-2"
      >
        Copy
      </button>

      <button
        type="button"
        class="btn btn-outline-danger btn-sm mt-2"
        @click="confirm = true"
      >
        Remove
      </button>

      <button type="button" class="btn btn-outline-success btn-sm mt-2">
        Start
      </button>
      <button type="button" class="btn btn-outline-secondary btn-sm mt-2">
        Stop
      </button>

      <!-- Confirmation -->
      <div v-if="confirm">
        <EditJson
          :edit="{
            confirm: 'Remove',
            cmdConfirm: 'map-remove',
            name: mapId,
            params: [{ name: 'regionId', value: regionId }],
          }"
          @save="confirm = false"
          @cancel="confirm = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EditJson from "./EditJson.vue";

const cmdOnlineUnsubscribe = "online-unsubscribe/";
const cmdOnlineSubscribe = "online-subscribe/";
const cmdMapDetails = "map/";
const cmdOnline = "online/";

export default {
  components: { EditJson },
  name: "MapDetails",
  props: {
    regionId: String,
    mapId: String,
    filter: String,
    filterOnline: Number,
  },
  data() {
    return {
      mapDetails: null,
      confirm: false,
      online: { online: false, numUsers: 0 },
      filterDetails: {
        id: "id",
        game: "game",
        level: "level",
        region: "region",
        config: "config",
        ip: "ip",
        port: "port",
      },
    };
  },
  mounted: function () {
    let that = this;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }
      switch (gw.command) {
        case cmdMapDetails + that.regionId + "/" + that.mapId:
          that.mapDetails = JSON.parse(data);
          break;

        case cmdOnline + that.mapId: {
          let online = that.online.online;
          let onlineDiff = 0;

          let numUsers = that.online.numUsers;
          let numUsersDiff = 0;

          that.online = JSON.parse(data);

          // get online diff
          if (that.online.online != online) {
            if (that.online.online) onlineDiff = 1;
            else onlineDiff = -1;
          }

          // get players diff
          if (that.online.numUsers != numUsers) {
            numUsersDiff = that.online.numUsers - numUsers;
          }

          that.$emit("countOnline", onlineDiff, numUsersDiff);

          break;
        }
      }
    });

    // Send WebRTC server 'maps details' command when connected to WebRTC
    this.teoweb.whenConnected(() => {
      that.teoweb.sendCmd(cmdMapDetails + that.regionId + "/" + that.mapId);
      that.teoweb.sendCmd(cmdOnline + that.mapId);
      that.teoweb.sendCmd(cmdOnlineSubscribe + that.mapId);
    });
  },

  unmounted: function () {
    this.teoweb.sendCmd(cmdOnlineUnsubscribe + this.mapId);
    this.teoweb.delReader(this.reader);
  },

  computed: {},

  methods: {
    checkFilter() {
      if (!this.mapDetails) {
        return false;
      }

      // filter online - offline button
      switch (this.filterOnline) {
        case 1: // online
          if (this.online.online != true) return false;
          break;
        case 2: // offline
          if (this.online.online != false) return false;
          break;
      }

      // Split filter by words
      let filtersArray = this.filter.toLowerCase().split(" ");

      // Compare all worlds in filter with fields from mapDetails
      for (let i = 0; i < filtersArray.length; i++) {
        let value = filtersArray[i];
        if (!value.length) {
          continue;
        }

        // Additional filter modification for refinement search
        // Need write special word from map configuration and symbol ":"
        let filtersDetailsArray = value.toLowerCase().split(":");
        if (filtersDetailsArray.length == 2) {
          let wordId = filtersDetailsArray[0].toLowerCase();
          let valueId = filtersDetailsArray[1].toLowerCase();

          if (wordId.length && valueId.length) {
            switch (String(wordId)) {
              case this.filterDetails.id:
                if (!this.mapId.toLowerCase().includes(valueId)) return false;
                break;
              case this.filterDetails.game:
                if (!this.mapDetails.GameName.toLowerCase().includes(valueId))
                  return false;
                break;
              case this.filterDetails.level:
                if (!this.mapDetails.LevelName.toLowerCase().includes(valueId))
                  return false;
                break;
              case this.filterDetails.region:
                if (this.mapDetails.AwsRegion != Number(valueId)) return false;
                break;
              case this.filterDetails.config:
                if (!this.mapDetails.ConfigFile.toLowerCase().includes(valueId))
                  return false;
                break;
              case this.filterDetails.ip:
                if (!this.mapDetails.ServerIP.toLowerCase().includes(valueId))
                  return false;
                break;
              case this.filterDetails.port:
                if (!this.mapDetails.ServerPort.toLowerCase().includes(valueId))
                  return false;
                break;
              default:
                return false;
            }
            continue;
          }
          return false;
        }

        // Check simple parameter
        if (
          !(
            this.mapDetails.GameName && this.mapDetails.GameName.toLowerCase().includes(value) ||
            this.mapDetails.LevelName.toLowerCase().includes(value) ||
            this.mapDetails.AwsRegion == Number(value) ||
            this.mapDetails.ConfigFile.toLowerCase().includes(value) ||
            this.mapDetails.ServerIP.toLowerCase().includes(value) ||
            this.mapDetails.FriendlyName.toLowerCase().includes(value) ||
            this.mapDetails.ServerPort.toLowerCase().includes(value) ||
            this.mapId.toLowerCase().includes(value)
          )
        ) {
          return false;
        }
      }

      return true;
    },
  },
};
</script>

<style>
.btn-sm {
  margin-right: 2px;
}
.server-online {
  color: green;
}
.server-offline {
  color: gray;
}
</style>
