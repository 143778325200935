<template>
  <div>
    <div class="players-list">
      <!-- v-show="!edit.mode" -->
      <!-- List group -->
      <!-- https://getbootstrap.com/docs/4.0/components/list-group/ -->
      <ul class="list-group">
        <li
          class="list-group-item"
          v-for="player in players.Result"
          :key="player"
        >
          <Player :game="player.game" :id="player.id" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Player from "./Player.vue";

const cmdPlayers = "players-list";
const cmdPlayersChanged = "players-list-changed";
const cmdPlayersSubscribe = "players-list-subscribe";
const cmdPlayersUnsubscribe = "players-list-unsubscribe";

export default {
  name: "PlayersList",
  components: {
    Player,
  },
  data() {
    return {
      players: {
        Result: [],
      },
    };
  },

  mounted: function () {
    const that = this;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }
      switch (gw.command) {
        case cmdPlayers:
          that.players = JSON.parse(data);
          if (that.players.Result == null) {
            that.players.Result = [];
          }
          break;

        case cmdPlayersChanged:
          that.updatePlayers(JSON.parse(data));
          break;
      }
    });

    // Send cmdPlayers commands to WebRTC server when connected
    this.teoweb.whenConnected(() => {
      this.teoweb.sendCmd(cmdPlayers);
      this.teoweb.sendCmd(cmdPlayersSubscribe);
    });
  },

  unmounted: function () {
    this.teoweb.sendCmd(cmdPlayersUnsubscribe);
    this.teoweb.delReader(this.reader);
  },

  methods: {
    updatePlayers(playersChange) {
      const element = { game: playersChange.game, id: playersChange.id };
      const players = this.players.Result;

      // Add element to players
      if (playersChange.direction == "+") {
        players.push(element);
        return;
      }

      // Remove element from players
      let i = 0;
      while (i < players.length) {
        if (players[i].game == element.game && players[i].id == element.id) {
          players.splice(i, 1);
          break;
        }
        i++;
      }
    },
  },
};
</script>

<style>
.players-list {
  min-width: 350px;
  max-width: 635px;
}
.players-list li.list-group-item {
  border: none;
  padding: inherit;
}
.players-list li.list-group-item h3 {
  margin-top: 20px;
}
</style>
