<template>
  <div class="group" v-if="group">
    <h3>{{ id }}</h3>
    <ul class="list-group">
      <li class="list-group-item" v-for="player in group.members" :key="player">
        {{ player }}
      </li>
    </ul>
    <!-- Remove group button -->
    <button
      type="button"
      class="btn btn-outline-danger btn-sm mt-2"
      @click="confirm = true"
    >
      Remove
    </button>
    <!-- Confirm remove buttons -->
    <div v-if="confirm">
      <div class="mt-2">Please confirm your Remove action:</div>
      <input
        type="button"
        class="btn btn-primary btn-sm mt-2"
        value="Yes"
        @click="delGroup(id)"
      />
      <input
        type="button"
        class="btn btn-secondary btn-sm mt-2"
        value="No"
        @click="confirm = false"
      />
    </div>
  </div>
</template>

<script>
const cmdGroup = "group/";
const cmdGroupDel = "group-del/";

const prefix = "/api/v1/";

export default {
  name: "Group",
  props: {
    id: String,
  },
  data() {
    return {
      group: null,
      confirm: false,
    };
  },

  mounted: function () {
    let that = this;
    let cmd = cmdGroup + this.id;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }
      switch (gw.command) {
        case cmd:
          that.group = JSON.parse(data);
          break;
      }
    });

    // Send 'group/{id}' command to WebRTC server every 5 seccont after connected
    this.teoweb.whenConnected(function () {
      that.teoweb.sendCmd(cmd);
    });
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    delGroup(id) {
      this.axios.get(prefix + cmdGroupDel + id).then((/*response*/) => {
        // this.name = response.data;
        // localStorage.name = this.name;
      });
    },
  },
};
</script>

<style scoped>
.btn-sm {
  margin-right: 2px;
}
.lines {
  white-space: pre;
}
</style>
