<template>
  <div>
    <div class="groups-list">
      <!-- v-show="!edit.mode" -->
      <!-- List group -->
      <!-- https://getbootstrap.com/docs/4.0/components/list-group/ -->
      <ul class="list-group">
        <li class="list-group-item" v-for="group in groups.Result" :key="group">
          <Group :id="group" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Group from "./Group.vue";

const cmdGroups = "groups-list";
const cmdGroupsChanged = "groups-list-changed";
const cmdGroupsSubscribe = "groups-list-subscribe";
const cmdGroupsUnsubscribe = "groups-list-unsubscribe";

export default {
  name: "GroupsList",
  components: {
    Group,
  },
  data() {
    return {
      groups: {
        Result: [],
      },
    };
  },

  mounted: function () {
    const that = this;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }
      switch (gw.command) {
        case cmdGroups:
          that.groups = JSON.parse(data);
          if (that.groups.Result == null) {
            that.groups.Result = [];
          }
          break;

        case cmdGroupsChanged:
          that.updateGroups(JSON.parse(data));
          break;
      }
    });

    // Send cmdGroups commands to WebRTC server when connected
    this.teoweb.whenConnected(() => {
      this.teoweb.sendCmd(cmdGroups);
      this.teoweb.sendCmd(cmdGroupsSubscribe);
    });
  },

  unmounted: function () {
    this.teoweb.sendCmd(cmdGroupsUnsubscribe);
    this.teoweb.delReader(this.reader);
  },

  methods: {
    updateGroups(groupsChange) {
      const element = groupsChange.id;
      const groups = this.groups.Result;

      // Add element to groups
      if (groupsChange.direction == "+") {
        groups.push(element);
        return;
      }

      // Remove element from groups
      let i = 0;
      while (i < groups.length) {
        if (groups[i] == element) {
          groups.splice(i, 1);
          break;
        }
        i++;
      }
    },
  },
};
</script>

<style>
.groups-list {
  min-width: 350px;
  max-width: 635px;
}
.groups-list li.list-group-item {
  border: none;
  padding: inherit;
}
.groups-list li.list-group-item h3 {
  margin-top: 20px;
}
</style>
