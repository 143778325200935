<template>
  <div>
    <h1>Settings</h1>
    <EditConfig
      name="Edit MM Conditions"
      jsonName="Matchmaking conditions"
      subject="Matchmaking conditions used when selected game server for player by level."
      config="matchmaking/conditions"
    />
    <EditConfig
      name="Edit Scaling Conditions"
      jsonName="Scaling conditions"
      subject="Scaling conditions used to start or stop game servers by level."
      config="scaling/conditions"
    />
    <EditConfig
      name="Edit Scaling instances parameters"
      jsonName="Scaling instances parameters"
      subject="Scaling instances parameters used to select game servers by region and level."
      config="scaling/instanceParams"
    />
    <UpdateAMI />
    <ApplyConfigs />
  </div>
</template>

<script>
import UpdateAMI from "../components/UpdateAMI.vue";
import EditConfig from "../components/EditConfig.vue";
import ApplyConfigs from "../components/ApplyConfigs.vue";

export default {
  name: "Settings",
  components: {
    UpdateAMI,
    EditConfig,
    ApplyConfigs
  },
};
</script>
