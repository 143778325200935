<template>
  <div>
    <h1>Player Groups</h1>
    <GroupsList />
  </div>
</template>

<script>
import GroupsList from "../components/GroupsList.vue";

export default {
  name: "Groups",
  components: {
    GroupsList,
  },
};
</script>
