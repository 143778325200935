<template>
  <div>
    <h1>Players</h1>
    <PlayersList />
  </div>
</template>

<script>
import PlayersList from "../components/PlayersList.vue";

export default {
  name: "Players",
  components: {
    PlayersList,
  },
};
</script>
