<template>
  <div>
    <h1>Hardware Servers</h1>
    <HardList />
  </div>
</template>

<script>
import HardList from "../components/HardList.vue";

export default {
  name: "Hards",
  components: {
    HardList,
  },
};
</script>
