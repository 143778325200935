<template>
  <div v-if="canSave">
    <button
      class="btn btn-outline-secondary dropdown-toggle"
      type="button"
      @click="doShow"
    >
      {{ name }}
    </button>
    <div class="subject">{{ subject }}</div>
    <div v-if="showForm">
      <EditJson :edit="edit" @save="doSave" @cancel="doShow" />
    </div>
  </div>
</template>

<script>
import EditJson from "./EditJson.vue";

const cmdGetConfig = "get-config/";
const cmdAccessWrite = "access-write";

export default {
  name: "EditConfig",
  components: {
    EditJson,
  },
  props: {
    name: String,
    jsonName: String,
    subject: String,
    config: String,
  },
  data() {
    return {
      showForm: false,
      canSave: true,
      edit: {
        name: this.jsonName,
        json: { someField: "someValue" },
      },
    };
  },

  mounted: function () {
    let that = this;
    this.reader = this.teoweb.addReader(function (gw, data) {
      // console.debug(
      //   "execute reader in 'UpdateAMI.vue' with parameters, gw:",
      //   gw,
      //   "data:",
      //   data
      // );
      switch (gw.command) {
        case cmdAccessWrite:
          if (!gw.err) {
            that.canSave = true;
          }
          break;

        case cmdGetConfig + that.config: {
          let jsonStr = "" + data;
          console.debug("cmdGetConfig answer received", jsonStr);
          if (gw.err) {
            that.error = "Error: " + gw.err;
            break;
          }

          that.edit.json = JSON.parse(jsonStr);
          that.showForm = true;
          break;
        }
      }
    });

    // Send cmdAccessWrite amd cmdMaps commands to WebRTC server when connected
    this.teoweb.whenConnected(() => this.teoweb.sendCmd(cmdAccessWrite));
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    doShow() {
      // showForm = !showForm;
      if (!this.showForm) {
        this.teoweb.sendCmd(cmdGetConfig + this.config);
        // this.showForm = true;
        return;
      }
      this.showForm = false;
    },

    /** send update ami request */
    doSave() {
      // this.teoweb.sendCmd(cmdSaveConfig + this.config, this.edit);
    },
  },
};
</script>

<style scoped>
.subject {
  color: gray;
  font-size: small;
  margin-bottom: 15px;
}
.config-edit {
  margin-bottom: 25px;
}
</style>
